/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
// core components

function IndexHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      // const updateScroll = () => {
        // let windowScrollTop = window.pageYOffset / 3;
        // pageHeader.current.style.transform =
        //   "translate3d(0," + windowScrollTop + "px,0)";
      // };
      // window.addEventListener("scroll", updateScroll);
      // return function cleanup() {
      //   window.removeEventListener("scroll", updateScroll);
      // };
    }
  });

  return (
    <>
      <div className="page-header clear-filter">
      <a href="/">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/background.jpg") + ")"
          }}
          ref={pageHeader}
        ></div>
        </a>
        
        <Container>
          <div className="content-center brand">
            <h1>Web Development & DevOps</h1>
            <h5>ReactJS - NodeJS - ElectronJS - Docker - Kubernetes - CI/CD</h5>
          </div>
        </Container>
      </div>
    </>
  );
}

export default IndexHeader;
